.container {
  z-index: 10;
  left: 0;
  bottom: 0;
  width: 100%;
  position: fixed;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--mantine-color-white);
  border-top: 1px solid var(--mantine-color-gray-2);

  @mixin dark {
    background-color: var(--mantine-color-black);
    border-top: 1px solid var(--mantine-color-gray-8);
  }

  @media (min-width: $mantine-breakpoint-sm) {
    display: none;
  }
}

.indicator {
  flex-grow: 0;
}
