.clickable {
  cursor: pointer;
}

.selected {
  /* kiwi-2, 0.6 opacity */
  background: rgba(199, 255, 156, 0.6);

  @mixin dark {
    /* kiwi-4, 0.2 opacity */
    background: rgba(126, 255, 31, 0.2);
  }
}
