.footer {
  flex-grow: 1;
  padding: 50px;
  border-top: 1px solid;

  border-color: var(--mantine-color-gray-3);
  background: var(--mantine-color-white);

  a {
    color: var(--mantine-color-lime-6);
  }

  @mixin dark {
    border-color: var(--mantine-color-gray-7);
    background: var(--mantine-color-gray-8);

    a {
      color: var(--mantine-color-lime-4);
    }
  }

  @media (max-width: $mantine-breakpoint-sm) {
    padding-bottom: 80px;
  }
}
