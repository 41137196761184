.Header_header__zfHy9 {
  z-index: 2;
  position: relative;
  background-color: var(--mantine-color-lime-6);
}

  [data-mantine-color-scheme='light'] .Header_header__zfHy9 {
    border-bottom: 1px solid var(--mantine-color-lime-7);
}

  [data-mantine-color-scheme='dark'] .Header_header__zfHy9 {
    border-bottom: 1px solid var(--mantine-color-lime-5);
}

.Header_title__mOAWX {
  flex-wrap: nowrap;
  flex-shrink: 0;
}

.Header_inner__PB0ql {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}

.Header_dropdown__lLRGg {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 0;
  border-radius: 0 0 4px 4px;
  border-bottom: 1px solid;
  overflow: hidden;
  border-color: var(--mantine-color-lime-7);
  background-color: var(--mantine-color-lime-6);
}

.Header_links__RRVLA {
  flex-grow: 1;
  display: flex;
  justify-content: end;
}

@media (max-width: 48em) {

.Header_links__RRVLA {
    display: none
}
  }

@media (min-width: 48em) {

.Header_burger__VR25s {
    display: none
}
  }

.Header_link__k3_FO {
  display: block;
  line-height: 1;
  padding: 8px 12px;
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  color: var(--mantine-color-gray-9);
  font-size: var(--mantine-font-size-sm);
}

@media (hover: hover) {

  .Header_link__k3_FO:hover {
    background-color: var(--mantine-color-lime-7);
  }
}

@media (hover: none) {

  .Header_link__k3_FO:active {
    background-color: var(--mantine-color-lime-7);
  }
}

.Header_linkLabel__mFtVg {
  margin-right: 5;
}

.Header_action__mbfh1 {
  font-weight: normal;
  background-color: var(--mantine-color-black);
}

@media (hover: hover) {

  .Header_action__mbfh1:hover {
    background-color: var(--mantine-color-gray-9);
  }
}

@media (hover: none) {

  .Header_action__mbfh1:active {
    background-color: var(--mantine-color-gray-9);
  }
}

@media (min-width: 48em) {

.Header_action__mbfh1 {
    margin-left: 16px
}
  }

@media (max-width: 36em) {

.Header_action__mbfh1 {
    padding: 0 12px
}
  }

.MobileBottomNavBar_container__SjzcX {
  z-index: 10;
  left: 0;
  bottom: 0;
  width: 100%;
  position: fixed;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--mantine-color-white);
  border-top: 1px solid var(--mantine-color-gray-2);
}

  [data-mantine-color-scheme='dark'] .MobileBottomNavBar_container__SjzcX {
    background-color: var(--mantine-color-black);
    border-top: 1px solid var(--mantine-color-gray-8);
}

  @media (min-width: 48em) {.MobileBottomNavBar_container__SjzcX {
    display: none
}
  }

.MobileBottomNavBar_indicator__kBaNS {
  flex-grow: 0;
}

.Footer_footer__UaG1g {
  flex-grow: 1;
  padding: 50px;
  border-top: 1px solid;

  border-color: var(--mantine-color-gray-3);
  background: var(--mantine-color-white);
}

  .Footer_footer__UaG1g a {
    color: var(--mantine-color-lime-6);
  }

  [data-mantine-color-scheme='dark'] .Footer_footer__UaG1g {
    border-color: var(--mantine-color-gray-7);
    background: var(--mantine-color-gray-8);
}

  [data-mantine-color-scheme='dark'] .Footer_footer__UaG1g a {
      color: var(--mantine-color-lime-4);
    }

  @media (max-width: 48em) {.Footer_footer__UaG1g {
    padding-bottom: 80px
}
  }

.HubEventSelectionTile_clickable__qtQK6 {
  cursor: pointer;
}

.HubEventSelectionTile_selected__eMIyd {
  /* kiwi-2, 0.6 opacity */
  background: rgba(199, 255, 156, 0.6);
}

[data-mantine-color-scheme='dark'] .HubEventSelectionTile_selected__eMIyd {
    /* kiwi-4, 0.2 opacity */
    background: rgba(126, 255, 31, 0.2);
}

