.header {
  z-index: 2;
  position: relative;
  background-color: var(--mantine-color-lime-6);

  @mixin light {
    border-bottom: 1px solid var(--mantine-color-lime-7);
  }

  @mixin dark {
    border-bottom: 1px solid var(--mantine-color-lime-5);
  }
}

.title {
  flex-wrap: nowrap;
  flex-shrink: 0;
}

.inner {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}

.dropdown {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 0;
  border-radius: 0 0 4px 4px;
  border-bottom: 1px solid;
  overflow: hidden;
  border-color: var(--mantine-color-lime-7);
  background-color: var(--mantine-color-lime-6);
}

.links {
  flex-grow: 1;
  display: flex;
  justify-content: end;

  @media (max-width: $mantine-breakpoint-sm) {
    display: none;
  }
}

.burger {
  @media (min-width: $mantine-breakpoint-sm) {
    display: none;
  }
}

.link {
  display: block;
  line-height: 1;
  padding: 8px 12px;
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  color: var(--mantine-color-gray-9);
  font-size: var(--mantine-font-size-sm);

  @mixin hover {
    background-color: var(--mantine-color-lime-7);
  }
}

.linkLabel {
  margin-right: 5;
}

.action {
  font-weight: normal;
  background-color: var(--mantine-color-black);

  @mixin hover {
    background-color: var(--mantine-color-gray-9);
  }

  @media (min-width: $mantine-breakpoint-sm) {
    margin-left: 16px;
  }

  @media (max-width: $mantine-breakpoint-xs) {
    padding: 0 12px;
  }
}
